import DireccionamientoCliente from './componentes/DireccionamientoCliente';
import './App.css';
import UsersProvider from './context/UsersContext';
import fondounas2 from './img/fondounas2.png';

function App() {
  return (
    <div className="App">
      <UsersProvider>
        <div className='content'>
          <DireccionamientoCliente />
        </div>
        <img src={fondounas2} className='imgFondo'></img>
      </UsersProvider>
    </div>
  );
}

export default App;
