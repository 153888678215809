import { createContext, useState } from "react";


export const UsersContext = createContext()

const UsersProvider = ({children}) => {
    const urlfetch1 = process.env.REACT_APP_CONSULTA_DIRECCIONES
    const urlfetch2 = process.env.REACT_APP_CONSULTA_CLIENTE
    //const urlfetch1 = "http://localhost/citas/php/consultaDirecciones.php"
    //const urlfetch2 = "http://localhost/citas/php/consultaCliente.php"
    const[datosUsuariosCliente, setDatosUsuariosCliente] = useState([])
    const[datosUsuariosAdmin, setDatosUsuariosAdmin] = useState([])
    const[loadingDUC, setLoadingDUC] = useState(true)
    const[userLogged, setUserLogged] = useState([])


    const getUsuariosCliente = (link) => {
        let linkadmin = new FormData()
        linkadmin.set("link",link)
        fetch(urlfetch2,{
            method: 'POST',
            body: linkadmin
        })
        .then(res => res.json())
        .then(data => {
            setDatosUsuariosCliente(data)
            setLoadingDUC(false)
            console.log(datosUsuariosCliente)
        })
    }

    let global = {datosUsuariosCliente, setDatosUsuariosCliente, userLogged, setUserLogged, getUsuariosCliente, loadingDUC}

    return(
        <UsersContext.Provider  value={global}>{children}</UsersContext.Provider>
    )

}
export default UsersProvider