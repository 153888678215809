import { Fragment, useContext, useState, useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react";
import { UsersContext } from "../context/UsersContext";
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';

import "../css/Cliente.css"
import atras from '../img/atras.svg'
import adelante from '../img/adelante.svg'

const Cliente = ({link}) => {
    const guardarusuarioscliente = process.env.REACT_APP_GUARDAR_USUARIOS_CLIENTE
    const guardarwhatsapp = process.env.REACT_APP_GUARDAR_WHATSAPP
    const [userLogged, setUserLogged] = useState()
    const [whats, setWhats] = useState(false)
    const [nuevoRegistro, setNuevoRegistro] =useState()
    const navigate = useNavigate()
    const { loginWithRedirect, user, isAuthenticated, isLoading, logout } = useAuth0()
    const { datosUsuariosCliente, getUsuariosCliente, loadingDUC } = useContext(UsersContext)
    
   
    let existe
    const autenticar = () => {
        if(isAuthenticated){
            if(!datosUsuariosCliente){
                alert("no cargo usuarios")
            }
            console.log("login ok")
            console.log(datosUsuariosCliente)

            if(loadingDUC === false){
                existe = datosUsuariosCliente.filter(usuario => usuario.nombreU === user.name && usuario.id_login[0] === user.sub[0])
                console.log(existe)
                
                    if(existe.length){
                        //setLoading(!loading)
                        console.log("el usuario existe")
                        setUserLogged(existe[0])
                        navigate(`/${existe[0].link_admin}/`)
                        
                    }else if(existe.length === 0){
                        console.log("el usuario no existe")
                        newUser()
                    }
            }else{
                loginWithRedirect({authorizationParams:{redirect_uri:"https://cliente.clickynail.com/#/"+link}})
            }

            
            
        }else{
            
            loginWithRedirect({authorizationParams:{redirect_uri:"https://cliente.clickynail.com/#/"+link}})
        }
    }


    const newUser = () => {
        let newuser = new FormData()
        newuser.set("id_login",user.sub)
        newuser.set("nombreU", user.name)
        newuser.set("wpU", "")
        newuser.set("link_admin", link)
        newuser.set("tipo_cuenta", "cliente")
        newuser.set("img_url", user.picture)

        fetch(guardarusuarioscliente,{
            method: 'POST',
            body: newuser
        })
        .then(res => res.json())
        .then(data => {if(data == "hecho"){
            console.log("se guardo el nuevo cliente")
            getUsuariosCliente(link)
            
        }})
    }

    const guardarWA = () => {
        
        let wa = new FormData()
        wa.set("idUser",userLogged.ID)
        wa.set("link_admin", link)
        wa.set("wpU", document.querySelector(".numeroWA").value)
        
        
        fetch(guardarwhatsapp,{
            method: 'POST',
            body: wa
        })
        .then(res => res.json())
        .then(data => {if(data == "hecho"){
            console.log("se guardo el WA")
            getUsuariosCliente(link)
            setWhats(true)
           
        }})
    }
    

    useEffect(()=>{
        getUsuariosCliente(link)
        if(isAuthenticated){
            autenticar()
        }

    },[isLoading])
 
    if(!userLogged){
        if(isLoading){
            return(
                <div className="fondo-cargando">
                      <p>Cargando...</p>
                </div>
            )
        }else{
            return(
                <Fragment>
                        <LoginCliente
                        isAuthenticated={isAuthenticated} 
                        loginWithRedirect={loginWithRedirect} 
                        autenticar={autenticar}/>
                </Fragment>
            ) 

        }
    }else{
        if(loadingDUC){
            return(
                <div className="fondo-cargando">
                      <p>Cargando...</p>
                </div>
            )
        }else{
            return(
                <Fragment>
                        {userLogged.wpU || whats ?
                            <Logged
                            logout={logout}
                            setUserLogged = {setUserLogged}
                            userLogged = {userLogged}
                            link={link}
                            />
                            :
                            <VentanaReqWA guardarWA={guardarWA}/>
                        }
                </Fragment>
            ) 
        }
    }
    
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const LoginCliente = ({isAuthenticated, loginWithRedirect, autenticar}) => {
    return(
        <Fragment>
            <div className="fondo-login-cliente">
                <div className="fondo-iniciar">
                    <button className="btn" onClick={autenticar}>Iniciar sesion</button>
                </div>
            </div>
        </Fragment>
    )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const  Logged = ({logout, setUserLogged, userLogged, link}) => {
    const consultarespacios = process.env.REACT_APP_CONSULTA_ESPACIOS
    const consulta = process.env.REACT_APP_CONSULTA
    const citaexiste = process.env.REACT_APP_CITA_EXISTE
    const nuevacitacliente = process.env.REACT_APP_NUEVA_CITA_CLIENTE
    const borrarC = process.env.REACT_APP_BORRAR_CITA_CLIENTE
    const disponibilidad =[{"08:00:00":true, "10:00:00": true, "12:00:00": true, "14:00:00": true, "16:00:00": true, "18:00:00": true, "20:00:00": true,}]
    const [fechahoy, setFechahoy] = useState(Date())
    const [horarios, setHorarios] = useState([])
    const [citas, setCitas] = useState([])
    const [ioMenu, setIoMenu] = useState(false)
    const [menu, setMenu] = useState("agendar")
    
    let dia = new Date(fechahoy)

    const cerrarsesion = () => {
        setUserLogged([])
        logout({ logoutParams: { returnTo: "https://cliente.clickynail.com/#/"+link } })
    }

    const actualizarDia = (hoy, operacion) => {
        if(operacion === "suma"){
            hoy.setDate(hoy.getDate() + 1);
            setFechahoy(hoy)
            
        }else if(operacion === "resta"){
            hoy.setDate(hoy.getDate() - 1)
            setFechahoy(hoy) 
          
        }else{
            setFechahoy(hoy)
        } 
        document.querySelector(".fecha").value = formatoFecha(dia)
        getHorarios()
        getCitasHabilitadas()
        
    }

    const actualizarDia2 = () => {
        let fecha2 = new Date(document.querySelector(".fecha").value)
        fecha2.setDate(fecha2.getDate() + 1)
        setFechahoy(fecha2)
        getHorarios()
        getCitasHabilitadas()
    }

    const getHorarios = () => {
        let linkadmin = new FormData()
        linkadmin.set("link",link)
        fetch(consultarespacios ,{
            method: 'POST',
            body: linkadmin
        })
        .then(res => res.json())
        .then(data => {setHorarios(data)
           
        })
    }

    const getCitasHabilitadas = () => {
        let linkadmin = new FormData()
        linkadmin.set("link",link)
        fetch(consulta,{
            method: 'POST',
            body: linkadmin
        })
        .then(res => res.json())
        .then(data => {setCitas(data)
            
        })
    }

    const formatoFecha = (datos) => {
        let aniof = datos.getFullYear().toString()
        let mesf = (datos.getMonth()+1).toString()
        let diaf = datos.getDate().toString()
      
        if(mesf.length === 1){
            mesf = mesf.padStart(2, '0')  
        }
        if(diaf.length === 1){
            diaf = diaf.padStart(2, '0')   
        }
        return aniof+"-"+mesf+"-"+diaf
    }

    const guardarCita = (e) => {
        e.preventDefault()
        //comnparar si existe la cita
        let nuevaCitaCliente = new FormData()
        nuevaCitaCliente.set("link", link)
        nuevaCitaCliente.set("idusuario", userLogged.ID)
        nuevaCitaCliente.set("nombre", userLogged.nombreU)
        nuevaCitaCliente.set("fecha", e.target.parentElement.id)
        nuevaCitaCliente.set("hora", e.target.id) 
        nuevaCitaCliente.set("wp", userLogged.wpU)
        nuevaCitaCliente.set("confirmado", "0")

        fetch(citaexiste,{
            method: 'POST',
            body: nuevaCitaCliente
        })
        .then(res => res.json())
        .then(data => {if(data === "existe"){
            console.log("la cita ya no esta disponibre")
            swal({
                text: "Este horario ya no esta disponible"
            })
            getCitasHabilitadas()
        }else if(data === "noexiste"){
            console.log("la cita esta disponible")
            fetch(nuevacitacliente,{
            method: 'POST',
            body: nuevaCitaCliente
            })
            .then(res => res.json())
            .then(data => {
                if(data === "hecho"){
                    console.log("se guardo la nueva cita")
                    getHorarios()
                    getCitasHabilitadas()
                    swal({
                        title:"Confirmar",
                        text:"Confirmas que deceas agendar una cita para la fecha "+ (new Date(e.target.parentElement.id)).getDate()+"-"+(new Date(e.target.parentElement.id)).getMonth()+"-"+(new Date(e.target.parentElement.id)).getFullYear() + " a las "+e.target.id,
                        buttons:["No", "Si"]
                    }).then(res=> {
                        if(res){

                            nuevaCitaCliente.set("confirmado", "1")
                            fetch(nuevacitacliente,{
                                method: 'POST',
                                body: nuevaCitaCliente
                            })
                            .then(res => res.json())
                            .then(data => {if(data == "hecho"){
                                console.log("se confirmo la cita")
                            }})

                        }else{
                            nuevaCitaCliente.set("confirmado", "borrar")
                            fetch(nuevacitacliente,{
                                method: 'POST',
                                body: nuevaCitaCliente
                            })
                            .then(res => res.json())
                            .then(data => {if(data == "hecho"){
                                console.log(" NO se confirmo la cita")
                                getHorarios()
                                getCitasHabilitadas()
                            }})


                        }
                    })
                }

            })
            }})
    }


    let filtro = dia.getFullYear()+"-"+(dia.getMonth()+1)+"-"+dia.getDate()
    let filtrado = horarios.filter(horario => horario.fechaid ===filtro)
    let filtradoCitas =  citas.filter(cita => cita.fecha === formatoFecha(new Date(filtro)))
    filtradoCitas.map(x =>  {
        let hora1 = x.hora
        disponibilidad[0][hora1] = false
    })
    
   let misCitas = citas.filter(cita => cita.idusuario === userLogged.ID)
   console.log(misCitas)
   let misCitasActivas = misCitas.filter(cita => ((new Date(cita.fecha)).getFullYear() > (new Date()).getFullYear()) || ((new Date(cita.fecha)).getFullYear() == (new Date()).getFullYear() && (new Date(cita.fecha)).getMonth() > (new Date()).getMonth()) || ((new Date(cita.fecha)).getFullYear() == (new Date()).getFullYear() && (new Date(cita.fecha)).getMonth() == (new Date()).getMonth() && (new Date(cita.fecha)).getDate()+1 >= (new Date()).getDate() ))
   console.log(misCitasActivas)
   /////////////////////////////////////////////aqui
   console.log(misCitas[4])
   console.log(new Date().getDate())
    const accionMenu = () => {
        setIoMenu(!ioMenu)
    }

    const changeMenu = (opmenu) => {
        setIoMenu(!ioMenu)
        setMenu(opmenu)
        
    }

    const borrarCita = (e) => {
        e.preventDefault()
        console.log(e.target.id)
        let idCitaBorrar = new FormData()
        idCitaBorrar.set("link", link)
        idCitaBorrar.set("idborrar", e.target.id)
        let citaBorrar = misCitas.filter(cita => cita.ID === e.target.id)
        console.log(citaBorrar[0])
        swal({
            title:"Confirmar",
            text:"Confirmas que deceas eliminar la cita para la fecha "+ ((new Date(citaBorrar[0].fecha)).getDate()+1)+"-"+((new Date((citaBorrar[0].fecha))).getMonth()+1)+"-"+(new Date(citaBorrar[0].fecha)).getFullYear() + " a las "+citaBorrar[0].hora,
            buttons:["No", "Si"]
        }).then(res=> {
            if(res){
                fetch(borrarC,{
                    method: 'POST',
                    body: idCitaBorrar
                    })
                    .then(res => res.json())
                    .then(data => {
                        if(data === "hecho"){
                            getHorarios()
                            getCitasHabilitadas()
                            swal({
                                text:"Se borro la cita con fecha "+ ((new Date(citaBorrar[0].fecha)).getDate()+1)+"-"+((new Date((citaBorrar[0].fecha))).getMonth()+1)+"-"+(new Date(citaBorrar[0].fecha)).getFullYear() + " a las "+citaBorrar[0].hora,
                            })
                        }
                    })

               

            }else{
               
                

            }
        })
    }

    
  
    useEffect(() => {
        actualizarDia(dia)
        getHorarios()
        getCitasHabilitadas()
    },[])
    useEffect(() => {
        if(menu==="agendar"){
            document.querySelector(".fecha").value = formatoFecha(dia)
        }
    },[menu])


    return(
        <Fragment>
            
                
            
            
            <div className="menu-cliente">
                {!ioMenu ? 
                    <div className="btn-menu" onClick={accionMenu}>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>:
                    <div className="btn-X" onClick={accionMenu}>X</div>
                }
                <div className="nombre-usuario">{userLogged.nombreU}</div>
                <img className='img-usuario' src={userLogged.img_url} />
                
            </div>
            {ioMenu && <VentanaMenu changeMenu={changeMenu} cerrarsesion={cerrarsesion}/>}
            
            

            {menu === "agendar"? 
            <>
                <div className="contenedorFecha">
                    <img src={atras} className="flechaAtras"  onClick={() => {actualizarDia(dia, "resta")}}/> 
                    <input type="date" className="fecha" onChange={actualizarDia2}/>
                    <img src={adelante} className="flechaAdelante" onClick={() => {actualizarDia(dia, "suma")}} />
                </div>
        
                {filtrado.length && filtrado[0].activo==="1" ? <div className="horarios" id={filtrado[0].fechaid}>
                    <p>8:00 am</p>{filtrado[0].hora1==="1" && disponibilidad[0]["08:00:00"] ?<p id="08:00:00" className="horadisponible" onClick={guardarCita}>Disponible</p>  :<p className="horanodisponible">No disponible</p>}
                    <p>10:00 am</p>{filtrado[0].hora2==="1" && disponibilidad[0]["10:00:00"] ?<p id="10:00:00" className="horadisponible" onClick={guardarCita}>Disponible</p>:<p className="horanodisponible">No disponible</p>}
                    <p>12:00 pm</p>{filtrado[0].hora3==="1" && disponibilidad[0]["12:00:00"] ?<p id="12:00:00" className="horadisponible" onClick={guardarCita}>Disponible</p>:<p className="horanodisponible">No disponible</p>}
                    <p>2:00 pm</p>{filtrado[0].hora4==="1" && disponibilidad[0]["14:00:00"] ?<p id="14:00:00" className="horadisponible" onClick={guardarCita}>Disponible</p>:<p className="horanodisponible">No disponible</p>}
                    <p>4:00 pm</p>{filtrado[0].hora5==="1" && disponibilidad[0]["16:00:00"] ?<p id="16:00:00" className="horadisponible" onClick={guardarCita}>Disponible</p>:<p className="horanodisponible">No disponible</p>}
                    <p>6:00 pm</p>{filtrado[0].hora6==="1" && disponibilidad[0]["18:00:00"] ?<p id="18:00:00" className="horadisponible" onClick={guardarCita}>Disponible</p>:<p className="horanodisponible">No disponible</p>}
                    <p>8:00 pm</p>{filtrado[0].hora7==="1" && disponibilidad[0]["20:00:00"] ?<p id="20:00:00" className="horadisponible" onClick={guardarCita}>Disponible</p>:<p className="horanodisponible"> No disponible</p>}
                </div>:<div className="horarios dianodisponible">Dia no disponible</div>}
            </>:
            menu === "miscitas"?
            <>
                <div className="titulo-mis-citas"><h1>Mis Citas</h1></div>
                {misCitasActivas.map((cita, index)=>
                    <>
                        <div className="citasbotonera" id={cita.fecha}><button className="citasboton" id={cita.ID} onClick={borrarCita}>Cancelar Cita</button></div>
                        <div className="citas" id={cita.ID}>
                            <p>Fecha:    {cita.fecha}</p>
                            <p>Hora:    {cita.hora}</p>
                        </div>
                    </>
                )}
            </>:
            <>
            </>
            }
            
            
            
        </Fragment>
    )
    
}
const VentanaMenu = ({changeMenu , cerrarsesion}) => {
    return(
        <Fragment>
        <div className="ventanaMenu">
        <hr></hr>
            <ul>   
                <li onClick={()=>changeMenu("agendar")}><h2>Agendar Cita</h2></li> 
                <li onClick={()=>changeMenu("miscitas")}><h2>Mis Citas</h2></li> 
                <li onClick={cerrarsesion}><h2>Cerrar Sesion</h2></li>  
            </ul>
        </div>
    </Fragment>
    )
}
const VentanaReqWA = ({guardarWA}) => {
    return(
        <Fragment>
        <div className="ventanaReqWA">
            <h2>Faltan algunos datos para terminar tu registro</h2>
            <div>Ingresa tu numero de WhatsApp</div>
            <input type="text" className="numeroWA"/>
            <button className="guardarNWA" onClick={guardarWA}>Guardar</button>
           
        </div>
    </Fragment>
    )
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default Cliente