import { Fragment, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRouteCliente from "./ProtectedRouteCliente";
import Cliente from "../paginas/Cliente";

const DireccionamientoCliente = () => {
    const consultadirecciones = process.env.REACT_APP_CONSULTA_DIRECCIONES
    const [direcciones, SetDirecciones] =  useState([])
    //const urldirecciones = "http:/localhost/citas/php/consultaDirecciones.php"
    //console.log(urldirecciones)
    
    const getDirecciones = () => {
        fetch(consultadirecciones)
        .then(res => res.json())
        .then(data => SetDirecciones(data))
    }

    useEffect(() => {
        getDirecciones()
    },[])

    console.log(direcciones)
    return(
        <Fragment>
            <Routes>
                {direcciones.map((direccion, index) => 
                    <>
                        <Route  
                            path={`/${direcciones[index].link}/*`} 
                            element={
                                <ProtectedRouteCliente link={direcciones[index].link}>
                                    <Cliente link={direcciones[index].link}/>
                                </ProtectedRouteCliente>
                            } 
                        />
                    </>
                )}
               
            </Routes>

        </Fragment>
    )
}
export default DireccionamientoCliente;